import React from 'react';
import { connect } from 'react-redux';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { deleteVideo, showWindow } from 'domain/actions/restaurants.action';

interface IProps {
  videoId: string | null;
}

const DeleteVideoWin: React.FC<IProps> = (props: any) => {
  const { videoId, show, showWindow, deleteVideo } = props;

  const accept = () => deleteVideo(videoId)
  const reject = () => {}

  return <ConfirmDialog visible={show} onHide={() => showWindow('delete_video', false)} 
      message={`Вы действительно хотите удалить Видео?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

const mapStateToProps = (state: any) => ({
  show: state.restaurants.windows.delete_video,
})

export default connect(mapStateToProps, {showWindow, deleteVideo})(DeleteVideoWin)