import * as constants from "domain/constants/restaurants.constant";
import { ICity } from "domain/types/city.type";
import * as types from "domain/types/restaurants.type";
import { IUser } from "domain/types/users.type";

export function getRestaurants(){
  return {
    type: constants.RESTAURANT_SAGA_GET
  }
}

export function getRestaurantById(id: string){
  return {
    type: constants.RESTAURANT_SAGA_GET_BY_ID,
    payload: { restaurantId: id }
  }
}

export function getManagerByRestaurant(id: string){
  return {
    type: constants.RESTAURANT_SAGA_GET_MANAGER_BY_RESTAURANT,
    payload: { restaurantId: id }
  }
}

export function getBidsUpdate(){
  return {
    type: constants.RESTAURANT_SAGA_GET_ALL_UPDATE
  }
}

export function getBidUpdateById(id: string){
  return {
    type: constants.RESTAURANT_SAGA_GET_BID_UPDATE_BY_ID,
    payload: { bidId: id }
  }
}

export function getKitchens(){
  return {
    type: constants.RESTAURANT_SAGA_GET_KITCHENS,
  }
}

export function getTagsDishes(){
  return {
    type: constants.RESTAURANT_SAGA_GET_TAGS_DISHES,
  }
}

export function getRestaurantCategories(){
  return {
    type: constants.RESTAURANT_SAGA_GET_CATEGORIES,
  }
}

export function getDishesRestaurant(){
  return {
    type: constants.RESTAURANT_SAGA_GET_DISHES_BY_RESTAURANT_ID,
  }
}

export function getKitchenById(kitchenId:string){
  return {
    type: constants.RESTAURANT_SAGA_GET_KITCHEN_BY_ID,
    payload: { kitchenId: kitchenId }
  }
}

export function getCategories(){
  return {
    type: constants.RESTAURANT_SAGA_GET_CATEGORIES_DISHES,
  }
}

export function getCategoriesHookah(){
  return {
    type: constants.RESTAURANT_SAGA_GET_CATEGORIES_HOOKAH,
  }
}

export function getCategoryById(){
  return {
    type: constants.RESTAURANT_SAGA_GET_CATEGORY_DISHES_BY_ID,
  }
}

export function postYoutubeVideo(restaurantId: string, data: any){
  return {
    type: constants.RESTAURANT_SAGA_POST_YOUTUBE,
    payload: {
      restaurantId,
      ...data
    }
  }
}

export function getVideosRutube(restaurantId: string){
  return {
    type: constants.RESTAURANT_SAGA_GET_VIDEOS_RUTUBE,
    payload: {
      restaurantId
    }
  }
}

export function postRutubeVideo(restaurantId: string, data: any){
  return {
    type: constants.RESTAURANT_SAGA_POST_RUTUBE,
    payload: {
      restaurantId,
      ...data
    }
  }
}

export function postVideo(restaurantId: string, data: any){
  return {
    type: constants.RESTAURANT_SAGA_POST_VIDEO,
    payload: {
      restaurantId,
      ...data
    }
  }
}

export function deleteRutubeVideo(id: string){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_VIDEO_RUTUBE,
    payload: {
      id
    }
  }
}

export function deleteVideo(id: string){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_VIDEO,
    payload: {
      id
    }
  }
}

export function uploadRestaurantsByXls(file: any) {
  return {
    type: constants.RESTAURANT_SAGA_UPLOAD_XLS,
    payload: { file }
  }
}

export function uploadDishesXls(file: any) {
  return {
    type: constants.RESTAURANT_SAGA_UPLOAD_XLS_DISHES,
    payload: { file }
  }
}

export function updateAverages(){
  return {
    type: constants.RESTAURANT_SAGA_UPDATE_AVERAGE,
  }
}

export function createCategory(category: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_POST_CATEGORIES_DISHES,
    payload: { category }
  }
}

export function createCategoryHookah(category: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_POST_CATEGORIES_HOOKAH,
    payload: { category }
  }
}

export function updateCategory(category: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_PUT_CATEGORIES_DISHES,
    payload: { category }
  }
}

export function updateCategoryHookah(category: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_PUT_CATEGORIES_HOOKAH,
    payload: { category }
  }
}

export function createKitchen(kitchen: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_POST_KITCHENS,
    payload: { kitchen }
  }
}

export function updateKitchen(kitchen: types.ICategory){
  return {
    type: constants.RESTAURANT_SAGA_PUT_KITCHENS,
    payload: { kitchen }
  }
}

export function createDishes(dishes: types.IDishes, file: any){
  return {
    type: constants.RESTAURANT_SAGA_POST_DISHES,
    payload: { dishes, file }
  }
}

export function copyDishes(copyRestaurantId: string, file: any){
  return {
    type: constants.RESTAURANT_SAGA_COPY_DISHES,
    payload: { copyRestaurantId }
  }
}

export function createHookah(hookah: types.IHookah, file: any){
  return {
    type: constants.RESTAURANT_SAGA_POST_HOOKAH,
    payload: { hookah, file }
  }
}

export function editDishes(dishes: types.IDishes, file: any, clear?: boolean ){
  return {
    type: constants.RESTAURANT_SAGA_PUT_DISHES,
    payload: { dishes, file, clear }
  }
} 

export function deleteDishes(dishesId: string){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_DISHES,
    payload: { dishesId }
  }
}


export function deleteCategory(id: string, callback: () => void){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_CATEGORY_NATIONAL,
    payload: { categoryId: id, callback }
  }
}

export function deleteCategoryDishes(id: string, callback: () => void){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_CATEGORY_DISHES,
    payload: { categoryId: id, callback }
  }
}

export function editHookah(hookah: types.IDishes, file: any){
  return {
    type: constants.RESTAURANT_SAGA_PUT_HOOKAH,
    payload: { hookah, file }
  }
} 

export function deleteHookah(hookahId: string){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_HOOKAH,
    payload: { hookahId }
  }
}

export function deleteRestaurant(){
  return {
    type: constants.RESTAURANT_SAGA_DELETE
  }
}

export function createRestaurant(restaurantData: types.IPostRestaurant){
  return {
    type: constants.RESTAURANT_SAGA_POST,
    payload: { data: restaurantData }
  }
}

export function updateRestaurant(restaurantData: any, isCheck: boolean){
  return {
    type: constants.RESTAURANT_SAGA_PUT,
    payload: { data: restaurantData, isCheck }
  }
}

export function getBidsMy(){
  return {
    type: constants.RESTAURANT_SAGA_GET_BIDS,
  }
}

export function getBidsHelps(){
  return {
    type: constants.RESTAURANT_SAGA_GET_BIDS_HELPS,
  }
}

export function completeBid(id:string){
  return {
    type: constants.RESTAURANT_SAGA_COMPLETE_BID,
    payload: { bidId: id }
  }
}

export function deleteBid(id:string){
  return {
    type: constants.RESTAURANT_SAGA_DELETE_BID,
    payload: { bidId: id }
  }
}

export function denyBid(id:string){
  return {
    type: constants.RESTAURANT_SAGA_DENY_BID,
    payload: { bidId: id }
  }
}

export function completeBidUpdate(){
  return {
    type: constants.RESTAURANT_SAGA_COMPLETE_BID_UPDATE,
  }
}

export function denyBidUpdate(){
  return {
    type: constants.RESTAURANT_SAGA_DENY_BID_UPDATE,
  }
}

export function bindOwner(owner: IUser){
  return {
    type: constants.RESTAURANT_SAGA_BIND_OWNER,
    payload: { ownerId: owner.accountId }
  }
}

export function setActiveRestaurant(restaurantId:string, isActive: boolean){
  return {
    type: constants.RESTAURANT_SAGA_SET_ACTIVE,
    payload: { restaurantId, isActive }
  }
}

export function saveCoordinate(data: types.ICoordinate){
  return {
    type: constants.RESTAURANT_SAGA_POST_COORDINATE,
    payload: data
  }
}

export function setRestaurants(restaurants: Array<types.IRestaurant>): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET,
    payload: { restaurants }
  }
}

export function setAllCountRestaurants(count: number): any {
  return {
    type: constants.RESTAURANT_REDUCE_SET_ALL_COUNT,
    payload: { count }
  }
}

export function setPageRestaurants(page: number): any {
  return {
    type: constants.RESTAURANT_REDUCE_SET_PAGE,
    payload: { page }
  }
}

export function setSizePageRestaurants(sizePage: number): any {
  return {
    type: constants.RESTAURANT_REDUCE_SET_SIZE_PAGE,
    payload: { sizePage }
  }
}

export function setCurrentRestaurant(restaurant: types.IRestaurant): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET_CURRENT,
    payload: { restaurant }
  }
}

export function setManagerRestaurant(manager: types.IManagerRestaurants): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET_RESTAURANT_MANAGER,
    payload: { manager }
  }
}

export function setCurrentBidUpdate(bid: types.IRestaurantBidUpdate): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET_CURRENT_BID_UPDATE,
    payload: { bid }
  }
}

export function setBidsMy(bids: Array<types.IRestaurantBidMy>): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET_BIDS,
    payload: { bids }
  }
}

export function setBidsHelps(helps: any[]){
  return {
    type: constants.RESTAURANT_REDUCE_SET_BIDS_HELPS,
    payload: { helps }
  }
}

export function setBidsUpdate(bids: Array<types.IRestaurantBidUpdate>): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_REDUCE_SET_BIDS_UPDATE,
    payload: { bids }
  }
}

export function setCategoriesDishes(categories: Array<types.ICategory>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_CATEGORIES_DISHES,
    payload: { categories }
  }
}

export function setTagsDishes(tags: Array<types.ICategory>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_TAGS_DISHES,
    payload: { tags }
  }
}

export function setCategoriesHookah(categories: types.ICategory[]) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_CATEGORIES_HOOKAH,
    payload: { categories }
  }
}

export function setCategories(categories: Array<types.ICategory>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_CATEGORIES,
    payload: { categories }
  }
}

export function setKitchens(kitchens: Array<types.ICategory>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_KITCHENS,
    payload: { kitchens }
  }
}

export function setDishes(dishes: Array<types.IDishes>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_RESTAURANT_DISHES,
    payload: { dishes }
  }
}

export function setHookah(hookah: Array<types.IHookah>) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_RESTAURANT_HOOKAH,
    payload: { hookah }
  }
}

export function setFilters(cities: ICity[], categories: types.ICategory[], status: string[], isFullness: boolean) {
  return {
    type: constants.RESTAURANT_REDUCE_SET_RESTAURANT_FILTERS,
    payload: { cities, categories, status, isFullness }
  }
}

export function setLike(like: string){
  return {
    type: constants.RESTAURANTS_REDUCE_SET_FILTER_LIKE,
    payload: { like }
  }
}

export function fullnessRestaurant(id: string, isFullness: boolean = false){
  return {
    type: constants.RESTAURANT_SAGA_SET_FULLNESS,
    payload: { id, isFullness }
  }
}

export function saveOrders(items: types.IDishes[], cb: () => void){
  return {
    type: constants.RESTAURANT_SAGA_SET_ORDERS_DISHES,
    payload: { dishes: items, cb }
  }
}

export function saveHookah(items: types.IDishes[], cb: () => void){
  return {
    type: constants.RESTAURANT_SAGA_SET_ORDERS_HOOKAH,
    payload: { hookah: items, cb }
  }
}

export function showWindow(window: string, isShow: boolean ): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function showCurrentWin(id: string, name: string){
  return {
    type: constants.RESTAURANT_SAGA_CURRENT_IS_SHOW_WINDOW,
    payload: { 
      typeWindow: name,
      restaurantId: id
    }
  }
}

export function reqRestaurants(isLoad: boolean): types.RestaurantsReduceTypes{
  return {
    type: constants.RESTAURANT_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}