interface IProps {
  thumbNail: string;
  className?: string;
}

export const PreviewVideo = (props: IProps) => {
  const {thumbNail, className = ''} = props 
  
  const src = thumbNail
  const onError = (e: any) => e.target.src = process.env.PUBLIC_URL + "/images/default_empty.png"

  return (
    <img src={src} onError={onError} alt={`video`} className={className}/>
  )
};