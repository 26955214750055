import { combineReducers } from 'redux'
import * as constants from "domain/constants/restaurants.constant";
import * as types from "domain/types/restaurants.type";
import { IStatusResponse } from 'domain/types/static.type';
import { ICity } from 'domain/types/city.type';


const initialState = {
  restaurants: [],
  pagination: {
    page: 1,
    pageSize: 10,
    count: 0
  },
  bids: {
    my: [],
    update: [],
    helps: []
  },
  current: {
    restaurant: null,
    dishes: [],
    update: null,
    manager: null
  },
  kitchen: {
    categories: [],
    nationality: [],
    tags: []
  },
  hookah: {
    categories: []
  },
  categories: [],
  filters: {
    cities: [],
    categories: [],
    status: [],
    like: "",
    isFullness: false
  },
  windows: { 
    add: false,
    update: false,
    views: false, 
    bind_owner: false,
    bind_manager: false,
    qr_restaurant: false, 
    delete: false, 
    bid_update: false,
    coordinate: false,
    deleteImage: false,
    addCategory: false,
    editCategory: false,
    addKitchen: false,
    editKitchen: false,
    addDishes: false,
    copyDishes: false,
    editDishes: false,
    filters: false,
    addCategoryHookah: false,
    editCategoryHookah: false,
    addHookah: false,
    editHookah: false,
    hookah: false,
    list_youtube: false,
    list_rutube: false,
    add_video_rutube: false,
    delete_video_rutube: false,
    list_video: false,
    add_video: false,
    delete_video: false,
    create_task: false,
    list_restaurants: false,
    warningActiveTask: false
  },
  request: {
    loading: false,
    error: null
  }
};

export function restaurants(state = initialState.restaurants, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET:
      return action.payload.restaurants;
    default:
      return state;
  }
}

export function pagination(state = initialState.pagination, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_PAGE:
      return {
        ...state,
        page: action.payload.page
      };
    case constants.RESTAURANT_REDUCE_SET_SIZE_PAGE:
      return {
        ...state,
        pageSize: action.payload.sizePage
      };
    case constants.RESTAURANT_REDUCE_SET_ALL_COUNT:
      return {
        ...state,
        count: action.payload.count
      };
    default:
      return state;
  }
}

export function bids(state = initialState.bids, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_BIDS:
      return {
        ...state,
        my: action.payload.bids
      };
    case constants.RESTAURANT_REDUCE_SET_BIDS_HELPS:
      return {
        ...state,
        helps: action.payload.helps
      };
    case constants.RESTAURANT_REDUCE_SET_BIDS_UPDATE:
      return {
        ...state,
        update: action.payload.bids
      };
    default:
      return state;
  }
}

export function current(state = initialState.current, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_CURRENT:
      return {
        ...state,
        restaurant: action.payload.restaurant
      };
    case constants.RESTAURANT_REDUCE_SET_CURRENT_BID_UPDATE:
      return {
        ...state,
        update: action.payload.bid
      };
    case constants.RESTAURANT_REDUCE_SET_RESTAURANT_DISHES:
      return {
        ...state,
        dishes: action.payload.dishes
      };
    case constants.RESTAURANT_REDUCE_SET_RESTAURANT_MANAGER:
      return {
        ...state,
        manager: action.payload.manager
      };
    default:
      return state;
  }
}

export function kitchen(state = initialState.kitchen, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_KITCHENS:
      return {
        ...state,
        nationality: action.payload.kitchens
      };
    case constants.RESTAURANT_REDUCE_SET_CATEGORIES_DISHES:
      return {
        ...state,
        categories: action.payload.categories
      };
    case constants.RESTAURANT_REDUCE_SET_TAGS_DISHES:
      return {
        ...state,
        tags: action.payload.tags
      };
    default:
      return state;
  }
}

export function hookah(state = initialState.hookah, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_CATEGORIES_HOOKAH:
      return {
        ...state,
        categories: action.payload.categories
      };
    default:
      return state;
  }
}

export function categories(state = initialState.categories, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_CATEGORIES:
      return action.payload.categories;
    default:
      return state;
  }
}

export function filters(state = initialState.filters, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_REDUCE_SET_RESTAURANT_FILTERS:
      const {cities, categories, status, isFullness } = action.payload;
      return {
        ...state,
        cities: cities ? cities : [],
        categories: categories ? categories : [],
        status: status ? status : [],
        isFullness: isFullness
      }
    case constants.RESTAURANTS_REDUCE_SET_FILTER_LIKE:
      return {
        ...state,
        like: action.payload.like
      };
    default:
      return state;
  }
}

export function request(state = initialState.request, action: types.RestaurantsReduceTypes): IStatusResponse {
  switch (action.type) {
    case constants.RESTAURANT_CHECKOUT_REQUEST:
      return {
        error: null,
        loading: action.payload.loading,
      };
    default:
      return state;
  }
}


export function windows(state = initialState.windows, action: types.RestaurantsReduceTypes): any {
  switch (action.type) {
    case constants.RESTAURANT_IS_SHOW:
      const { typeWindow, isShowWindow } = action.payload;
      return {
        ...state,
        [typeWindow]: isShowWindow
      };
    default:
      return state;
  }
}

export function getRestaurant(state: { restaurants: {current: { restaurant: types.IRestaurant }} }) {
  return state.restaurants.current.restaurant
}


export function getManagerRest(state: { restaurants: {current: { manager: null | types.IManagerRestaurants }} }) {
  return state.restaurants.current.manager
}

export function getPaginationRestaurant(state: { restaurants: {pagination: any} }) {
  return state.restaurants.pagination
}

export function getUpdateRestaurant(state: { restaurants: {current: { update: types.IRestaurantBidUpdate}} }) {
  return state.restaurants.current.update
}

export function getFiltersRestaurant(state: 
  { restaurants: 
    { filters: { cities: ICity[], categories: types.ICategory[], like: string, isFullness: boolean } } } ) {
  return state.restaurants.filters
}

export default combineReducers({
  restaurants,
  pagination,
  current,
  bids,
  kitchen,
  hookah,
  categories,
  filters,
  request,
  windows
})